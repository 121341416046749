import React, {useCallback, useEffect, useRef, useState} from "react";
import {Alert, Grid, List, ListItem, Stack, ToggleButton} from "@mui/material";
// import axios from "axios";
import {config} from "../config";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useEventListener from "@use-it/event-listener";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Drawer from "@mui/material/Drawer";
import {AiOutlineCaretRight} from "react-icons/ai";
import Typography from "@mui/material/Typography";
import Markdown from "react-markdown";
import "./qPilotStyles.css";
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'
import rehypeRaw from "rehype-raw";
import {useAppContext} from "../libs/appContextLib";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {usePreviousValue} from "../libs/usePreviousValue";

const  QBearMascot = "/images/qpilot-avatar-image.png";

export default function QPilotChatbot(props) {

    const [currentPrompt, setCurrentPrompt] = React.useState("")

    //Public API that will echo messages sent to it back to the client
    const [socketUrl, setSocketUrl] = useState(config.websocket.URL);
    const [messageHistory, setMessageHistory] = useState([]);
    const [aiMessage, setAiMessage] = useState("");

    const handleCurrentPrompt = React.useCallback((e) => {
        setCurrentPrompt(e.target.value)
    }, [])


    function getOS() {
        const userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
            macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    const operatingSystem = getOS()

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth", block: "end"})

    }

    useEffect(() => {
        scrollToBottom()
    }, [aiMessage, messageHistory]);


    const [commandKeyDown, setCommandKeyDown] = React.useState(false)


    function handler({key}) {
        if (String(key) === 'Meta') {
            setCommandKeyDown(!commandKeyDown)
        }
        if (commandKeyDown && String(key) === "Enter" && currentPrompt && currentPrompt.length > 0) {
            handleClickSendMessage()
            // props.sendChat(false, currentPrompt)
            setCommandKeyDown(false)
            setCurrentPrompt("")
        }
    }

    useEventListener('keydown', handler);

    const remarkMathOptions = {
        singleDollarTextMath: false,
    };

    const processText = (content) => {
        return content
            .replace(/\[/g, '$$$') // Replace all occurrences of [ with $$
            .replace(/\]/g, '$$$') // Replace all occurrences of ] with $$
            .replace(/\(/g, '$$$') // Replace all occurrences of ( with $$
            .replace(/\)/g, '$$$'); // Replace all occurrences of ) with $$
    }


    const [isLoadingMessage, setIsLoadingMessage] = React.useState(false)
    const [currentIncomingMessage, setCurrentIncomingMessage] = React.useState("")
    // WEBSOCKET LOGIC START


    const {currUser} = useAppContext();
    const {
        sendJsonMessage,
        lastJsonMessage,
        sendMessage,
        lastMessage,
        readyState
    } = useWebSocket(socketUrl, {
        filter: false,
        share: false,
        queryParams: {
            jwt: currUser.authToken,
            channel: "chat-stream"
        },
        onOpen: () => console.log('opened qPilotWs'),
        shouldReconnect: (closeEvent) => {
            // alert("qPilotWs closeEvent", closeEvent)
            return true
        },

        onMessage: (rawMessage) => {
            const message = JSON.parse(rawMessage.data)

            // ONLY USE IN DEBUG MODE
            // if (message['other_data'] && Object.keys(message['other_data']).length > 0 && message['other_data']['db_entries']) {
            //     // CATCH DB ENTRIES AND PASS TO PARENT COMPONENT
            //     props.handleMessageContext(message['other_data']['db_entries'])
            // }

            if (message && Object.keys(message).length > 0 && message.message.indexOf("Starting tool:") > -1 && aiMessage.indexOf("Analyzing...") <0) {
                setAiMessage((prev) => prev + "Analyzing...");
            }
            if (message && Object.keys(message).length > 0 && message.message.indexOf("Done tool:") > -1) {
                setAiMessage((prev) => prev.replace("Analyzing..."," "));
            }
            if (message && Object.keys(message).length > 0 && (message.message.indexOf("Starting tool:") > -1 || message.message.indexOf("Starting agent:") > -1 || message.message.indexOf("Done tool:") > -1 || message.message.indexOf("Tool output was:") > -1)) {
                return
            } else {
                console.log("onMessage message", message)
                setAiMessage((prev) => prev + message.message);
            }

            if (message && Object.keys(message).length > 0)
                if (message.message.indexOf("Done agent:") > -1) {
                    setAiMessage("")
                    setMessageHistory((prev) => prev.concat({
                        ...message,
                        message: message.message.replace("Done agent: AgentExecutor with output: ", "")
                    }));
                }

        }


    });


     // Run when the connection state (readyState) changes
    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            // alert("Alert - new subscription")
            sendJsonMessage({
                event: "subscribe",
                jwt: currUser.authToken,
                data: {
                    channel: "chat-stream",
                },
                keep: true
            })
        }
    }, [readyState])

// Try to reconnect if the user's auth token changes
    const previousAuthToken = usePreviousValue(currUser.authToken)

    useEffect(() => {
        if (readyState === ReadyState.OPEN && previousAuthToken !== currUser.authToken) {
            // alert(`Alert - auth token changed \n ${previousAuthToken} \n vs \n ${currUser.authToken}`)
            sendJsonMessage({
                event: "subscribe",
                jwt: currUser.authToken,
                data: {
                    channel: "chat-stream",
                },
            })
        }
    }, [currUser.authToken])

    const clearMessageHistory = () => {
        setMessageHistory([])
    }


    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];


    const handleClearConversation = () => {
        const outgoingMessage = {
            sender: {
                sender_id: currUser.id, sender_name: `${currUser.firstName} ${currUser.lastName}`
            }, message: "[CLEAR_CONVERSATION]"
        }
        sendJsonMessage({...outgoingMessage}, true)
        setMessageHistory([]);
        setCurrentPrompt("")
        setAiMessage("")
    }

    const handleClickSendMessage = useCallback(() => {
        const outgoingMessage = {
            sender: {
                sender_id: currUser.id, sender_name: `${currUser.firstName} ${currUser.lastName}`
            }, message: currentPrompt,
            channel: "chat-stream"
        }

        sendJsonMessage({...outgoingMessage}, true)
        setMessageHistory((prev) => prev.concat({...outgoingMessage}));
        setCommandKeyDown(false)
        setCurrentPrompt("")
    }, [currentPrompt]);


    // WEBSOCKET LOGIC END


    console.log("qPilotWs messageHistory", messageHistory)


    return (

         <Drawer
     open={true}
     variant={"permanent"}
     anchor={"right"}
     sx={{
         zIndex: 1199,
         width: props.qPilotDrawerWidth,
         flexShrink: 0,
         [`& .MuiDrawer-paper`]: {
             backgroundColor: "rgb(249, 249, 249)",
             width: props.qPilotDrawerWidth,
             boxSizing: "border-box"
         }
     }}
 >
     <Box sx={{pt: "70px", height: props.isMobile ? `30vh` : `calc(100vh - 70px)`}}>
         {<Tooltip placement="left" title={<span
             style={{fontSize: "1.35em"}}>{!props.showQPilotChat ? "Show QPilot" : "Hide QPilot"}</span>}>
             <ToggleButton
                 size={"small"}
                 value={"open"}
                 sx={{padding: 0}}
                 onClick={props.handleShowQPilotChatChange}>
                 {!props.showQPilotChat ? <Avatar
                         variant={"rounded"}
                         size={"small"}
                         src={QBearMascot}
                         sx={{color: "#1de9b6", backgroundColor: "#272931", width: 28, height: 28}}
                     />
                     :
                     <Box sx={{
                         color: "#fff",
                         backgroundColor: "#272931",
                         width: 28,
                         height: 28,
                         pt: "4px",
                         borderRadius: "3px",
                         '&:hover': {
                             backgroundColor: "#333",
                             color: "#999"
                         }
                     }}>
                         <AiOutlineCaretRight/>
                     </Box>}
             </ToggleButton>
         </Tooltip>
         }
         {props.showQPilotChat &&
        <Box sx={{width: "100%", height: "65vh"}}>
            {props.isInitializing && <Grid container spacing={3} alignItems={"center"}
                                           justifyContent={"center"}><Grid item xs={12} lg={12}>
                <Typography sx={{textAlign: "center"}}>
                    Initializing QPilot...
                </Typography>
            </Grid></Grid>}

            <Alert
                icon={false}
                sx={{".MuiAlert-message": {minWidth: "100%"}}}
                style={{
                    backgroundColor: "rgb(249, 249, 249)",
                    color: "#000000",
                    // whiteSpace: 'pre-line',
                    // height: props.height * 0.5,
                    height: "100%",
                    width: "100%",
                    maxHeight: "60vh",
                    padding: "0px",
                    flexShrink: 0,
                }}
                severity={"success"}>
                <div
                    style={{
                        width: "100%",
                        overflowY: "auto",
                        // flexDirection: "column",
                        // display: "flex",
                    }}>
                    <List sx={{ width: '100%'}}>
                    {messageHistory.map((item, i) =>
                        <ListItem key={"chat" + i} style={{
                            width: "100%",
                            display: "inline-flex",
                            justifyContent: item.sender['sender_id'] === "qpilot" ? "flex-start" : "flex-end",
                            alignItems: item.sender['sender_id'] === "qpilot" ? "flex-start" : "flex-end",
                        }}>
                    {/*        <Grid container*/}
                    {/*              justifyContent={item.sender['sender_id'] === "qpilot" ? "flex-start" : "flex-end"}*/}
                    {/*         style={{*/}
                    {/*    textAlign: item.sender['sender_id'] === "qpilot" ? "left" : "right",*/}
                    {/*    width: "100%",*/}
                    {/*}}>*/}
                                <Stack spacing={1}
                                       direction={item.sender['sender_id'] === "qpilot" ? "row" : "row-reverse"}>
                                    {item.sender['sender_id'] === "qpilot" &&
                                        <ListItemAvatar sx={{p: 0, m: 0, minWidth: 34}}>
                                        <Avatar
                                            variant={"rounded"}
                                            size={"small"}
                                            src={item.sender['sender_id'] === "qpilot" ? QBearMascot : undefined}
                                            sx={{
                                                color: "#FFFFFF",
                                                backgroundColor: "#272931",
                                                width: 28,
                                                height: 28,
                                            }}
                                        >{props.currUser.firstName.slice(0, 1) + props.currUser.lastName.slice(0, 1)}</Avatar>
                                    </ListItemAvatar>
                                    }
                                    <div style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: "0.5em",
                                        paddingRight: "0.5em",
                                        width: "100%",
                                        border: item.sender['sender_id'] === "qpilot" ? "0px #b6b6b6 solid" : "1px #4DA685 solid",
                                        borderRadius: "3px",
                                        color: item.sender['sender_id'] === "qpilot" ? undefined : "#FFFFFF",
                                        backgroundColor: item.sender['sender_id'] === "qpilot" ? undefined : "#4DA685"
                                    }}><Markdown
                                        remarkPlugins={[[remarkMath, remarkMathOptions]]}
                                        rehypePlugins={[rehypeRaw, rehypeKatex]}
                                        className={"QPilotStyles"}
                                    >{item.message}</Markdown>
                                    </div>
                                </Stack>
                            {/*</Grid>*/}
                        </ListItem>
                    )}
                        </List>

                    {aiMessage &&
                        <ListItem key={"incoming_message"} sx={{alignItems: "flex-start"}}>
                            <Stack spacing={1} direction={"row"}>
                                <ListItemAvatar sx={{p: 0, m: 0, minWidth: 34}}>
                                    <Avatar
                                        variant={"rounded"}
                                        size={"small"}
                                        src={QBearMascot}
                                        sx={{
                                            color: "#1de9b6",
                                            backgroundColor: "#272931",
                                            width: 28,
                                            height: 28,
                                        }}
                                    />
                                </ListItemAvatar>

                                <div style={{
                               paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingLeft: "0.5em",
                                        paddingRight: "0.5em",
                                        width: "100%",
                                        border: "0px #b6b6b6 solid",
                                        borderRadius: "3px",
                                        color: undefined,
                                        backgroundColor: undefined
                                }}><Markdown
                                    remarkPlugins={[[remarkMath, remarkMathOptions]]}
                                    rehypePlugins={[rehypeRaw, rehypeKatex]}
                                    className={"QPilotStyles"}
                                >{aiMessage}</Markdown>
                                </div>
                            </Stack>
                        </ListItem>}
                    <div ref={messagesEndRef}/>
                </div>


                {/*{props.isRunningQPilot &&*/}
                {/*    <ListItem key={"chat-incoming"}><ListItemAvatar*/}
                {/*        sx={{p: 0, m: 0, minWidth: 34}}>*/}
                {/*        <Avatar*/}
                {/*            variant={"rounded"}*/}
                {/*            size={"small"}*/}
                {/*            src={QBearMascot}*/}
                {/*            sx={{*/}
                {/*                color: "#1de9b6",*/}
                {/*                backgroundColor: "#272931",*/}
                {/*                width: 28,*/}
                {/*                height: 28*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </ListItemAvatar>*/}

                {/*        <div style={{height: 36, paddingTop: 4}}*/}
                {/*        >*/}
                {/*            Thinking...*/}
                {/*        </div>*/}
                {/*    </ListItem>*/}
                {/*}*/}
            </Alert>
            <Box sx={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "rgb(249, 249, 249)",
                paddingTop: "1em",
                paddingBottom: "1em",
                paddingLeft: "0.75em",
                paddingRight: "0.75em",
            }}>
                <Grid container pt={2} justfifyContent={"center"}>
                    <Grid item xs={12}>
                        <TextField sx={{fontSize: 12}} fullWidth
                                   onChange={handleCurrentPrompt}
                                   size={"small"}
                                   maxRows={3}
                                   autoFocus={true}
                                   multiline
                                   placeholder={"Message QPilot..."}
                                   value={currentPrompt}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant={"caption"}>{operatingSystem === "Mac OS" ? "command + Enter to send" : operatingSystem === "Windows" ? "CTRL + Enter to send" : null} </Typography>
                    </Grid>

                    <Grid container spacing={1} alignItems={"space-between"}
                          sx={{pt: "4px"}}>
                        <Grid item pt={1} pb={1}>
                            <Button onClick={handleClickSendMessage}
                                    disabled={!currentPrompt}
                                    variant={"contained"}
                            >
                                Send</Button>
                        </Grid>
                        <Grid item pt={1} pb={1}>
                            <Button onClick={handleClearConversation}
                                    disabled={messageHistory.length === 0}
                                    variant={"outlined"}
                            >
                                Clear</Button>
                        </Grid>
                    </Grid>
                </Grid>
                Connection Status: {connectionStatus}
            </Box>
        </Box>
         }
     </Box>
         </Drawer>
    )
}
//     const [isRunningQPilot, setIsRunningQPilot] = React.useState(false)
//     const [isInitializing, setIsInitializing] = React.useState(false)
//     const [qPilotResponse, setQPilotResponse] = React.useState([])
//     const [currentPrompt, setCurrentPrompt] = React.useState("")
//
//     const handleCurrentPrompt = React.useCallback((e) => {
//         setCurrentPrompt(e.target.value)
//     }, [])
//
//
//     const sendChat = async (initial, event) => {
//         if (initial) {
//             setIsInitializing(true)
//         } else {
//             setIsRunningQPilot(true)
//         }
//
//         setQPilotResponse([{message: currentPrompt, type: "user"}, ...qPilotResponse])
//         let incomingPrompt = currentPrompt
//         setCommandKeyDown(false)
//         setCurrentPrompt("")
//         await axios.post(`${config.REACT_APP_BACKEND_HOST}/qpilot-multi-source`, {
//             "firstName": props.currUser.firstName,
//             "cleanedText": currentPrompt
//         }, {
//             headers: {
//                 Authorization: props.currUser.authToken,
//             }
//         }).then((response) => {
//
//
//             const responseToSet = initial ?
//                 [{
//                     message: response.data,
//                     type: "bot"
//                 }] :
//                 incomingPrompt && incomingPrompt.length > 0
//                     ?
//                     [{
//                         message: response.data,
//                         type: "bot"
//                     }, {
//                         message: incomingPrompt,
//                         type: "user"
//                     }, ...qPilotResponse]
//                     : [{message: response.data, type: "bot"}, ...qPilotResponse]
//
//             setQPilotResponse(responseToSet)
//
//             initial && setIsInitializing(false)
//
//             setIsRunningQPilot(false)
//         }).catch((error) => {
//             !initial && setIsRunningQPilot(false)
//             initial && setIsInitializing(false)
//             console.log(`Error running chat: ${error}`)
//         })
//     }
//
//
//     useEffect(() => {
//         if (props.showQPilotChat && qPilotResponse.length === 0) {
//             sendChat(true)
//         }
//     }, [props.showQPilotChat])
//
//
//     const [commandKeyDown, setCommandKeyDown] = React.useState(false)
//
//     function handler({key}) {
//         if (String(key) === 'Meta') {
//             setCommandKeyDown(!commandKeyDown)
//         }
//         if (commandKeyDown && String(key) === "Enter" && currentPrompt && currentPrompt.length > 0) {
//             sendChat(false)
//         }
//     }
//
//     useEventListener('keydown', handler);
//
//     const messagesEndRef = useRef(null)
//
//     const scrollToBottom = () => {
//         messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
//     }
//
//     useEffect(() => {
//         scrollToBottom()
//     }, [qPilotResponse, isRunningQPilot]);
//
//     const suggestedQuestions = [{
//         prompt: "Why is the Business Digest view useful?",
//         id: 1
//     }]
//
//     const handleAddSuggestedPrompt = (e, data) => {
//         setCurrentPrompt(data.prompt)
//         suggestedQuestions.filter(item => item.id !== data.id)
//     }
//
//
//     const remarkMathOptions = {
//         singleDollarTextMath: false,
//     };
//
//     function getOS() {
//         const userAgent = window.navigator.userAgent,
//             platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
//             macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
//             windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
//             iosPlatforms = ['iPhone', 'iPad', 'iPod'];
//         let os = null;
//
//         if (macosPlatforms.indexOf(platform) !== -1) {
//             os = 'Mac OS';
//         } else if (iosPlatforms.indexOf(platform) !== -1) {
//             os = 'iOS';
//         } else if (windowsPlatforms.indexOf(platform) !== -1) {
//             os = 'Windows';
//         } else if (/Android/.test(userAgent)) {
//             os = 'Android';
//         } else if (/Linux/.test(platform)) {
//             os = 'Linux';
//         }
//
//         return os;
//     }
//
//     const operatingSystem = getOS()
//
//     return (
//
//         <Drawer
//             open={true}
//             variant={"permanent"}
//             anchor={"right"}
//             sx={{
//                 zIndex: 1199,
//                 width: props.qPilotDrawerWidth,
//                 flexShrink: 0,
//                 [`& .MuiDrawer-paper`]: {
//                     backgroundColor: "rgb(249, 249, 249)",
//                     width: props.qPilotDrawerWidth,
//                     boxSizing: "border-box"
//                 }
//             }}
//         >
//             <Box sx={{pt: "70px", height: props.isMobile ? `30vh` : `calc(100vh - 70px)`}}>
//                 {<Tooltip placement="left" title={<span
//                     style={{fontSize: "1.35em"}}>{!props.showQPilotChat ? "Show QPilot" : "Hide QPilot"}</span>}>
//                     <ToggleButton
//                         size={"small"}
//                         value={"open"}
//                         sx={{padding: 0}}
//                         onClick={props.handleShowQPilotChatChange}>
//                         {!props.showQPilotChat ? <Avatar
//                                 variant={"rounded"}
//                                 size={"small"}
//                                 src={QBearMascot}
//                                 sx={{color: "#1de9b6", backgroundColor: "#272931", width: 28, height: 28}}
//                             />
//                             :
//                             <Box sx={{
//                                 color: "#fff",
//                                 backgroundColor: "#272931",
//                                 width: 28,
//                                 height: 28,
//                                 pt: "4px",
//                                 borderRadius: "3px",
//                                 '&:hover': {
//                                     backgroundColor: "#333",
//                                     color: "#999"
//                                 }
//                             }}>
//                                 <AiOutlineCaretRight/>
//                             </Box>}
//                     </ToggleButton>
//                 </Tooltip>
//                 }
//
//                 {props.showQPilotChat &&
//                     <Box sx={{width: "100%", height: "65vh"}}>
//                         {isInitializing &&
//                             <Grid container spacing={3} alignItems={"center"} justifyContent={"center"}><Grid item
//                                                                                                               xs={12}
//                                                                                                               lg={12}>
//                                 <Typography sx={{textAlign: "center"}}>
//                                     Initializing QPilot...
//                                 </Typography>
//                             </Grid></Grid>}
//                         <Alert
//                             icon={false}
//                             style={{
//                                 backgroundColor: "rgb(249, 249, 249)",
//                                 color: "#000000",
//                                 // whiteSpace: 'pre-line',
//                                 height: "100%",
//                                 // maxHeight: "60vh",
//                                 padding: "0px",
//                             }}
//                             severity={"success"}>
//                             <div style={{
//                                 overflow: "auto",
//                                 display: "flex",
//                                 flexDirection: "column-reverse",
//
//                             }}>
//                                 {!isInitializing &&
//                                     qPilotResponse.map((item, i) =>
//                                         <ListItem key={"chat" + i} sx={{alignItems: "flex-start"}}>
//                                             {qPilotResponse.length > 0 &&
//                                                 <ListItemAvatar sx={{p: 0, m: 0, minWidth: 34}}>
//                                                     <Avatar
//                                                         variant={"rounded"}
//                                                         size={"small"}
//                                                         src={item.type === "bot" ? QBearMascot : (item.type && item.type) === "user" ? undefined : QBearMascot}
//                                                         sx={{
//                                                             color: "#1de9b6",
//                                                             backgroundColor: "#272931",
//                                                             width: 28,
//                                                             height: 28,
//                                                         }}
//                                                     >{props.currUser.firstName.slice(0, 1) + props.currUser.lastName.slice(0, 1)}</Avatar>
//                                                 </ListItemAvatar>
//                                             }
//
//                                             <div style={{
//                                                 paddingTop: 0,
//                                                 paddingBottom: 0,
//                                                 paddingLeft: "0.5em",
//                                                 paddingRight: "0.5em",
//                                                 width: "100%",
//                                                 border: item.type === "bot" ? "0px #b6b6b6 solid" : "1px #b6b6b6 solid",
//                                                 borderRadius: "3px",
//                                                 backgroundColor: item.type === "bot" ? "#b6b6b6" : undefined
//                                             }}><Markdown
//                                                 remarkPlugins={[[remarkMath, remarkMathOptions]]}
//                                                 rehypePlugins={[rehypeRaw, rehypeKatex]}
//                                                 className={"QPilotStyles"}>{item.message}</Markdown>
//                                             </div>
//                                         </ListItem>
//                                     )}
//                             </div>
//                             <div ref={messagesEndRef}/>
//
//
//                             {isRunningQPilot &&
//                                 <ListItem sx={{alignItems: "flex-start"}} key={"chat-incoming"}><ListItemAvatar
//                                     sx={{p: 0, m: 0, minWidth: 34, paddingTop: "4px"}}>
//                                     <Avatar
//                                         variant={"rounded"}
//                                         size={"small"}
//                                         src={QBearMascot}
//                                         sx={{
//                                             color: "#1de9b6",
//                                             backgroundColor: "#272931",
//                                             width: 34,
//                                             height: 34
//                                         }}
//                                     />
//                                 </ListItemAvatar>
//
//                                     <div style={{height: 36, paddingTop: 4, paddingLeft: "6px"}}>
//                                         Thinking...
//                                         {/*<CircularProgress size={18}/>*/}
//                                     </div>
//                                 </ListItem>
//                             }
//                         </Alert>
//                         <Box sx={{
//                             position: "sticky", bottom: 0, backgroundColor: "rgb(249, 249, 249)", paddingTop: "1em",
//                             paddingBottom: "1em",
//                             paddingLeft: "0.75em",
//                             paddingRight: "0.75em",
//                         }}>
//                             <Grid container pt={2}>
//                                 <Grid item xs={12}>
//                                     <TextField sx={{fontSize: 12}} fullWidth onChange={handleCurrentPrompt}
//                                                size={"small"}
//                                                maxRows={3}
//                                                autoFocus={true}
//                                                multiline
//                                                placeholder={"Message QPilot..."}
//                                                value={currentPrompt}/>
//                                 </Grid>
//                                 <Grid item xs={12}>
//                                     <Typography
//                                         variant={"caption"}>{operatingSystem === "Mac OS" ? "command + Enter to send" : operatingSystem === "Windows" ? "CTRL + Enter to send" : null} </Typography>
//                                 </Grid>
//                                 {/*{suggestedQuestions.map(item => <Chip key={item.id}*/}
//                                 {/*                                      onClick={(e) => handleAddSuggestedPrompt(e, item)}*/}
//                                 {/*                                      label={item.prompt}/>)}*/}
//                                 <Grid container spacing={1} alignItems={"space-between"} sx={{pt: "4px"}}>
//                                     <Grid item pt={1} pb={1}>
//                                         <Button onClick={(e) => sendChat(false, e)} disabled={!currentPrompt}
//                                                 variant={"contained"}>Send</Button>
//                                     </Grid>
//                                     <Grid item pt={1} pb={1}>
//                                         <Button onClick={props.handleShowQPilotChatChange}
//                                                 variant={"outlined"}>Hide QPilot</Button>
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                             {/*</form>*/}
//                         </Box>
//                     </Box>
//                 }
//             </Box>
//         </Drawer>
//
//     )
// }